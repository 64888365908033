// 我要申请步骤条数据处理
import { localDataPermission } from '@iebdc/utils/local-data';
import { getCookie } from '@iebdc/utils/cookies';
import { step } from '@public/sq.js';
const stepL = JSON.parse(JSON.stringify(step));

export default {
  namespaced: true,
  state: {
    /**
     * @params index 当前步骤条索引
     * @params title 当前步骤条标题
     * @params name 当前步骤条路由名称
     * @params permission 当前步骤条权限
     * @params code 流程代码在这个里面才需要去走核验
     * */
    //所有配置的步骤条
    stepList: stepL,
    //当前筛选的步骤条
    currentSelecte: [],
    // 当前激活步骤条
    activeItem: null,
    // 当前激活步骤条索引
    activeIndex: 0,
    // 步骤条下一步需要跳转的信息
    nextItem: null,
    //上一步需要跳转的信息
    prevItem: null,
  },
  getters: {
    // 获取步骤条数据，过滤没有权限的数据
    // getStepList: (state) => (currentSelectedFlowCode) => {
    //   const permissionArr = localDataPermission.get() || [];
    //
    //   // 根据权限过滤步骤条数据
    //   return state.stepList.filter((step) => permissionArr.includes(step.permission) && !step.hasOwnProperty('code'));
    // }
  },
  mutations: {
    getStepList(state, data) {
      const permissionArr = localDataPermission.get() || [];
      // 根据权限过滤步骤条数据
      state.currentSelecte = [
        ...state.stepList.filter((step) => {
          if (data.code === '') {
            return permissionArr.includes(step.permission) && !step.hasOwnProperty('code');
          } else {
            if (step.hasOwnProperty('areaCode')) {
              console.log(step.areaCode.toString());
              console.log(step.areaCode.indexOf(getCookie('selectedAreaCode')) > -1);
            }
            console.log(permissionArr.includes(step.permission), step.permission);
            return (
              //动态显示产权核验/提取楼盘表步骤
              //有权限的步骤条&&（拿到当前选择code值所在的步骤条||没有code属性的步骤条）

              permissionArr.includes(step.permission) &&
              ((step.hasOwnProperty('code') && step.code.indexOf(data.code) > -1) || !step.hasOwnProperty('code')) &&
              (((data.step === 'VERIFICATION' || data.step === 'VERIFYSUCCESS') && step.name !== 'intelligentAudit') ||
                (data.step !== 'VERIFICATION' && data.step !== 'VERIFYSUCCESS')) &&
              ((step.hasOwnProperty('areaCode') && step.areaCode.indexOf(getCookie('selectedAreaCode')) > -1) ||
                !step.hasOwnProperty('areaCode'))
            );
          }
        }),
      ];
      console.log(state.currentSelecte);
      // if (['N200108', 'N200110', 'N200104'].includes(data.code)) {
      //   let currentSelecteNewData = $.extend(true, [], state.currentSelecte);
      //   currentSelecteNewData.map((item, index) => {
      //     if (item.name === 'authorizedCertify') {
      //       console.log('筛选前', state.currentSelecte);
      //       state.currentSelecte.splice(index, 1);
      //     }
      //   });
      //   console.log(state.currentSelecte);
      // }
      // console.log("======",state.currentSelecte);
    },
    // 跳转到指定步骤条
    targetToStep(state, name) {
      // 找到步骤需要跳转的步骤条
      if (state.currentSelecte) {
        // 找到当前激活的步骤
        // 当前激活步骤
        state.activeItem = state.currentSelecte.find((step, index, arr) => {
          if (step.title === name) {
            // 赋值当前激活的索引
            state.activeIndex = index;
            // 下一步跳转所需数据
            state.nextItem = arr[index + 1];
            state.prevItem = arr[index - 1];
            return step;
          }
        });
      }
    },
  },
};
