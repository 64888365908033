/**
 * dashboard store
 * @description
 * 用户首页状态存储
 **/
 const dashboard1 = {
    namespaced: true,
    state: {
      finalAreaData: [],
      selectedBsdtCode: '',
      selectedBsdtTitle: '',
      selectedAreaCode: '',
      selectedAreaTitle: '',
      selectedOrganizationsCode: '',
      selectedOrganizationsTitle: '',
      selectedShiCode: '',
      selectedShiTitle: '',
      selectedShengCode: '',
      selectedShengTitle: '',
      bsdtDialogVisible: false
    },
    getters: {
      // 获取已经选择的机构信息
      getSelectedOrgInfo(state) {
        // 返回选择的登记大厅信息
        return {
          sheng: state.selectedShengTitle,
          djjg: state.selectedOrganizationsCode,
          jgmc: state.selectedOrganizationsTitle,
          bsdt: state.selectedBsdtCode,
          bsdtName: state.selectedBsdtTitle
        };
      }
    },
    mutations: {
      setFinalAreaData(state, payload) {
        state.finalAreaData = [...payload];
      },
      setSelectedBsdtCode(state, payload) {
        state.selectedBsdtCode = payload;
      },
      setSelectedBsdtTitle(state, payload) {
        state.selectedBsdtTitle = payload;
      },
      setSelectedAreaCode(state, payload) {
        state.selectedAreaCode = payload;
      },
      setSelectedAreaTitle(state, payload) {
        state.selectedAreaTitle = payload;
      },
      setSelectedOrganizationsCode(state, payload) {
        state.selectedOrganizationsCode = payload;
      },
      setSelectedOrganizationsTitle(state, payload) {
        state.selectedOrganizationsTitle = payload;
      },
      setSelectedShiCode(state, payload) {
        state.selectedShiCode = payload;
      },
      setSelectedShiTitle(state, payload) {
        state.selectedShiTitle = payload;
      },
      setSelectedShengCode(state, payload) {
        state.selectedShengCode = payload;
      },
      setSelectedShengTitle(state, payload) {
        state.selectedShengTitle = payload;
      },
      isShowBsdtDialog(state, isShow) {
        state.bsdtDialogVisible = isShow;
      }
    },
    actions: {}
  };
  export default dashboard1;
  