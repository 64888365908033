/**
 * 电子证照路由配置
 */
export default {
  path: '/home/djycx',
  name: 'djycx',
  component: () => import('iebdc/views/electronic-license/index1.vue'),
  meta: {
    label: '自治区可视化查询',
    isNotMenu: true,
    permission: 'IEBDC:SY:BDCZMCX', //IEBDC:SY:XXCX:DZZZ
    //construction: 'IEBDC:SY:XXCX:DZZZ:SFZJ', //IEBDC:SY:XXCX:DZZZ:SFZJ
    activeMenu: '/home/info-search',
    icon: require('../../assets/images/djtcx.png'),
    // background: 'linear-gradient(to bottom right, #8C10F8 , #C079FF)'
  },
};
