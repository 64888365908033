<template>
  <div>
    <object id="UCAPI" classid="CLSID:18201fef-1ac1-4900-8862-5ec6154bb307" style="display: none"></object>
    <!-- <object id="SMObj" classid="clsid:E5C44C76-610A-4C1F-9083-6CE933E3DC1D" style="display: none"></object> -->
    <div class="login-main-wrap">
      <div class="login-wrap">
        <div class="login-user-type pointer">
          <span
            v-for="(item, index) in userType"
            :key="index"
            :class="{ 'active-type': activeType === index }"
            @click="activeType = index"
            >{{ item.label }}</span
          >
        </div>
        <el-form :model="formData" class="login-form" :rules="rules" ref="loginForm">
          <div class="login-form-div">
            <el-form-item prop="ukvalue">
              <el-select style="width: 100%" v-model.trim="formData.ukvalue" placeholder="请选择安全证书">
                <el-option
                  v-for="item in showUserList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="pin">
              <el-input v-model.trim="formData.pin" placeholder="pin" class="input-text" show-password />
            </el-form-item>
          </div>
          <el-form-item>
            <el-button type="primary" class="login-button" @click.native="submit" @keyup.enter.native="submit"
              >立即登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import SIdentify from './component/identify';
import {
  getUser,
  isBlack,
  login,
  userInfo,
  getRandom,
  downloadExe,
  checkKey,
  checkTokenByAppKey,
  loginByUkey,
  // getCode
} from '../../api/login';
import { getAppInfo, getUserInfoByUserId } from '../../api/user-server.js';
import { REST_SERVICE } from '../../config';
import { crypto } from '../../utils/crypto.js';
import { isIe, CerticateCollectionX } from '@iebdc/utils/multibrowser';
import hasPermission from '../../mixin/hasPermission';
// import verCode from '../../mixin/ver-code';
import utils from '@/utils/utils';
import { mToken } from '@/utils/mToken';
const userTypes = ['gr', 'jg'];
import { mapMutations } from 'vuex';
import { localDataUser } from '../../utils/local-data';
export default {
  name: 'LoginDialog',
  mixins: [hasPermission],
  components: {
    SIdentify,
  },
  props: {
    isShow: Boolean,
  },
  data() {
    // 登录表单验证规则
    // 验证码校验
    // 验证码自定义验证规则
    const validateVerifycode = (rule, value, callback) => {
      let newVal = value.toLowerCase();
      let identifyStr = this.identifyCode.toLowerCase();
      if (newVal === '') {
        callback(new Error('请输入验证码'));
      } else if (newVal !== identifyStr) {
        callback(new Error('验证码不正确!'));
      } else {
        callback();
      }
    };
    /* const validateCode = (rule, value, callback) => {
      if (value !== 0 && !value) {
        callback(new Error('请输入验证码'));
      } else {
        if (value !== this.codeVerify) {
          // 刷新验证码
          getCode();
          callback(new Error('验证码有误'));
        }
        callback();
      }
    }; */
    return {
      serialNum: '', //uk秘钥
      // 新增记住密码
      checked: false,
      formData: {
        usernameOrPhone: '',
        password: '',
        code: '',
        value: '',
        pin: '',
      },
      identifyCodes: '1234567890ABCDEFGHIGKLMNoPQRSTUVWXYZ',
      identifyCode: '',
      rules: {
        usernameOrPhone: [{ required: true, message: '请输入用户名或电话号码', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }],
        code: [{ required: true, validator: validateVerifycode, trigger: 'change' }],
        ukvalue: [{ required: true, message: '安全证书不能为空', trigger: 'change' }],
        pin: [{ required: true, message: 'pin不能为空', trigger: 'change' }],
      },
      userType: [
        // { label: '个人登录', permission: 'IEBDC:DL:GR', value: 'gr' },
        { label: '经办人登录', permission: 'IEBDC:DL:QY', value: 'jg' },
      ],
      activeType: 1,
      certs: null,
      showUserList: [], // 安全证书列表
      formData: {}, // ukey登录表单
    };
  },
  created() {
    var _this = this;
    document.onkeydown = function (e) {
      if (e.keyCode === 13) {
        _this.submit();
      }
    };
  },
  watch: {
    activeType(val) {
      if (Number(val) === 1) {
        // 初始化金格uk
        this.initJinGe();
      }
    },
  },
  mounted() {
    // 验证码初始化
    this.identifyCode = '';
    this.makeCode(this.identifyCodes, 4);
    this.getCookie();

    this.initXinjiangCa();
  },
  methods: {
    beforeClose() {
      this.$emit('dialogBeforeClose');
    },
    // 新增验证码相关逻辑 start
    // 生成随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 切换验证码
    refreshCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成四位随机验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    // 新增验证码相关逻辑 end

    // 新增跳转修改密码
    gotoChangePassword() {
      this.$router.push({ path: '/change-password', query: { userType: this.userType[this.activeType].value } });
    },

    // 新增跳转 注册
    goPreSignIn() {
      this.$router.push('/sign-in');
    },

    // 新增记住密码--设置cookie
    setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      window.document.cookie = 'userName' + '=' + c_name + ';path=/;expires=' + exdate.toGMTString();
      window.document.cookie = 'userPwd' + '=' + c_pwd + ';path=/;expires=' + exdate.toGMTString();
    },
    // 新增记住密码--获取cookie
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; ');
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('=');
          if (arr2[0] === 'userName') {
            this.formData.usernameOrPhone = arr2[1];
          } else if (arr2[0] === 'userPwd') {
            this.formData.password = crypto.decryptByDES(arr2[1]);
          }
        }
      }
    },
    // 新增记住密码--清除cookie
    clearCookie: function () {
      this.setCookie('', '', -1);
    },
    // 金格uk只能在ie浏览器使用
    initJinGe() {
      if (isIe()) {
        if (SMObj.WebGetKeySN()) {
          this.serialNum = SMObj.WebGetKeySN();
        }
      }
    },
    initXinjiangCa() {
      this.certs = new mToken('mTokenPlugin');
      let ret = 0;
      ret = this.certs.SOF_LoadLibrary(this.certs.GM3000);

      if (ret != 0) {
        this.$message.warning('加载控件失败,错误码:' + this.certs.SOF_GetLastError());
        return;
      }
      let deviceName = this.certs.SOF_EnumDevice();
      if (deviceName != null) {
        let flag = this.certs.SOF_GetDeviceInstance(deviceName, '');
        if (flag != 0) {
          this.$message.warning('绑定应用失败，确定是否初始化Key,错误码:' + this.certs.SOF_GetLastError());
          return;
        }
        let cerList = this.certs.SOF_EnumCertContiner();
        var cert = this.certs.SOF_ExportUserCert(cerList, 1);
        if (cert != null && cert != '') {
          let str = this.certs.SOF_GetCertInfoByOid(cert, '2.5.29.14');
          let c_name = this.certs.SOF_GetCertInfo('', this.certs.SGD_CERT_SUBJECT_CN);
          let sn = this.certs.SOF_GetCertInfo('', this.certs.SGD_CERT_SERIAL);
          let certObj = {};
          certObj.label = c_name;
          certObj.value = sn;
          this.showUserList = [...this.showUserList, certObj];
        } else console.log('获取证书信息失败,错误码:' + this.certs.SOF_GetLastError());
      } else {
        this.$message.warning('未找到任何key');
      }
    },
    // 初始化ukey签名证书
    initUkey() {
      //声明证书集对象
      if (isIe()) {
        //IE
        this.certs = document.getElementById('UCAPI');
      } else {
        this.certs = new CerticateCollectionX();
      }
      //根据属性设置过滤条件(1表示使用SKF接口，2表示CSP(CSP不能枚举SM2证书)，)
      this.certs.setCryptoInterface(1);
      //软证书过滤标识(0禁用，1允许，默认0)
      //certs.setCF_SoftEnabled(1);
      //过滤签名证书算法("sm2","rsa")
      this.certs.setCF_CertAlg('sm2');
      //获取签名证书
      this.certs.setCF_KeyUsage(0x20);

      //加载证书集返回值等于0表示成功
      let loadResult = this.certs.Load();

      let len = this.certs.getSize();
      if (loadResult !== 0 || len < 1) {
        this.loginBtnDisable = true;
        // this.$message({
        //   message: '未获取到证书',
        //   type: 'error',
        // });
        return false;
      }
      this.loginBtnDisable = false;
      for (let i = 0; i < len; i++) {
        let cert = this.certs.GetAt(i);
        let certObj = {};
        certObj.label = cert.getFriendlyName();
        certObj.value = i;
        this.showUserList = [...this.showUserList, certObj];
      }
      this.formData.ukvalue = 0;
    },
    // 验证登录表单
    submit() {
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          this.loginByXinjiangCa();
          // this.loginByUK();
          // this.jinGrUk();

          // this.verifyUser(userTypes[this.activeType]);
        }
      });
    },
    loginByXinjiangCa(username) {
      let ret = this.certs.SOF_Login(this.formData.pin);
      if (ret != 0) {
        this.$message.warning('验证用户密码失败');
      } else {
        //密码验证成功
        loginByUkey({ key: crypto.encryptByDES(this.formData.ukvalue) }).then((res) => {
          if (res.success) {
            const parameter = {
              token: res.data.token,
              realName: res.data.user.realName,
              userId: res.data.user.id,
            };
            //普通登录存储用户状态,vuex
            this.setUserType(0);
            localDataUser.set({ token: res.data.token });
            this.getUserInfo(res.data.user.loginName, parameter, 0);
          } else {
            this.$message.warning(res.message);
          }
        });
      }
    },
    // 通过ukey登录
    loginByUK(username) {
      //声明签名证书对象
      let signCert;
      signCert = this.certs.GetAt(this.formData.ukvalue);
      let keySn = this.certs.GetKeySN();
      //设置pin码
      signCert.setUserPIN(this.formData.pin);
      signCert.setDefaultP7Digest('sha256');
      //签名(0表示attach，1表示detach)
      let randomData = signCert.GenRandom();
      let content = signCert.GetExtensionString('1.2.156.10260.4.1.1', 0);
      let signedData = signCert.PKCS7String(randomData, 0);
      if (randomData === '' || signedData === '') {
        this.$message.warning('PIN值验证失败');
        return;
      }
      const obj = this.showUserList.find((item) => {
        return item.value === this.formData.ukvalue;
      });
      const timestamp = new Date().getTime();
      const params = {
        password: this.formData.password,
        username: username,
        signedData,
        randomData,
        base64Code: '',
      };

      login(params, 'ukey').then((res) => {
        if (res && res.success) {
          const parameter = {
            token: res.data.gmsso_cli_ec_key,
          };
          // 登录成功后获取用户信息判断状态
          this.getUserInfo(username, parameter);
          //ukey之后使用vuex存储用户类型
          this.setUserType(this.activeType);
        } else {
          this.$message.success('登录失败！');
        }
      });
    },
    // 检测token
    checkTokenHandler(parameter, userInfo) {
      const params = {
        userId: parameter.userId,
        userName: parameter.userName,
      };
      Promise.all([getAppInfo(), getUserInfoByUserId(params, userInfo)]).then(() => {
        this.$message.success('登录成功！');
        this.isLoading = false;
        setTimeout(() => {
          location.reload();
          this.$router.push('/');
        }, 300);
      });
    },
    // 获取用户信息
    getUserInfo(username, parameter, type) {
      userInfo({ loginName: username }).then((res) => {
        if (res && res.success) {
          //判断是否验证
          const userInfo = res.data.userinfo ? res.data.userinfo : res.data.institutionalUser;
          // 用户类型
          if (type === 0) {
            userInfo.userType = userTypes[type];
          } else {
            userInfo.userType = userTypes[this.activeType];
          }

          // 同意联系电话字段
          userInfo.tel = userInfo.tel ? userInfo.tel : userInfo.lxdh;
          // 用户是否实名注册
          userInfo.authName = res.data.authName;
          this.checkTokenHandler(parameter, userInfo);
        }
      });
    },
    // 验证用户信息
    verifyUser(type) {
      this.formData.userType = type;
      const re = /^((0\d{2,3}-\d{7,8})|((13[0-9])|(14[5,7,9])|(15[^4])|(18[0-9])|(17[0,1,3,5,6,7,8]))\d{8})$/g;
      // 如果输入的是电话号码
      if (re.test(this.formData.usernameOrPhone)) {
        // 先根据号码获取用户 然后按照用户验证步骤来
        this.getUserName();
      } else {
        // 输入的是用户名 验证
        this.phoneExsitCheck();
      }
    },
    // 获取用户名（根据电话号码）
    getUserName(temp) {
      const params = {
        phone: this.formData.usernameOrPhone,
        userCategory: this.formData.userType === 'gr' ? 'gr' : 'qy',
      };
      getUser(params).then((res) => {
        if (res.data) {
          const username = res.data;
          this.phoneExsitCheck(username);
        }
      });
    },
    // 手机验证是否注册过
    phoneExsitCheck(username = this.formData.usernameOrPhone) {
      this.loginByXinjiangCa(username);
    },
    // 判断在不在黑名单内
    exsitisblack(username) {
      const params = {
        loginName: username,
      };
      isBlack(params).then((res) => {
        if (res.data) {
          this.$message.warning('黑名单限制登录！');
        } else {
          // 不在黑名单 登录
          this.login(username);
        }
      });
    },

    // 获取用户名（根据电话号码）
    getUkName() {
      const params = {
        phone: this.formData.usernameOrPhone,
        userCategory: this.formData.userType === 'gr' ? 'gr' : 'qy',
      };
      return new Promise((reslove) => {
        getUser(params).then((res) => {
          if (res.data && res.success) {
            reslove(res.data);
          } else {
            reslove(false);
          }
        });
      });
    },
    //注册
    ...mapMutations('user-type', ['setUserType']),
  },
};
</script>

<style scoped lang="scss">
@import '~@/pages/iebdc/styles/common-variables.scss';
@import '~@/pages/iebdc/styles/public.scss';
.login-main-wrap {
  text-align: center;
  .loginicon {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -9px;
    z-index: 10;
  }
  .sjhIcon {
    background: url('../../assets/images/login/sjh.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .mmIcon {
    background: url('../../assets/images/login/mm.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .yzmIcon {
    background: url('../../assets/images/login/yzm.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .login-wrap {
    margin: 0 auto;
    padding: 0 $spacing-medium;
    background-color: #fff;
    .login-user-type {
      margin-bottom: 20px;
      width: 100%;
      height: 45px;

      span {
        display: inline-block;
        font-size: 20px;
        text-align: center;
        line-height: 40px;
        width: 50%;
        height: 100%;
        color: #282828;
        font-weight: bolder;
        /*border-bottom: 2px solid #dcdfe6;*/
        @extend .transition-default;
      }

      .active-type {
        color: #2196f3;
        /*border-bottom: 2px solid #409eff;*/
      }
    }

    .login-form {
      .login-form-div {
        overflow: hidden;
      }

      /deep/.el-form-item {
        margin-bottom: 20px;

        .el-input__icon {
          color: #666666;
          font-size: 24px;
        }

        // 新增样式
        &.password-check {
          padding: 0px 20px;
          position: absolute;
          width: 100%;
          left: 0;
          // bottom: -20px;
          background: #f5f5f5;
          height: 54px;
          .right {
            float: right;
            line-height: 54px;
            color: #2196f3;
            /deep/.el-link {
              color: #2196f3;
            }
          }
          /deep/.el-checkbox {
            float: left;
            line-height: 54px;
            color: #282828;
          }
          /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
            color: #2196f3;
          }
          /deep/ .el-checkbox__inner {
            height: 17px;
            width: 17px;
            border: 2px solid #dcdfe6;
          }

          /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #fff;
          }

          /deep/ .el-checkbox__inner::after {
            top: 3px;
            left: 3px;
            background-color: #0d71f6;
            width: 7px;
            height: 7px;
          }

          /deep/ .el-checkbox__input.is-checked .el-checkbox__inner::after {
            transform: rotate(0deg) scaleY(1);
            -webkit-transform: rotate(0deg) scaleY(1);
          }

          .isChecked {
            /deep/ .el-checkbox__inner {
              border: 2px solid #0d71f6;
            }
          }
        }
      }

      /deep/ .el-input__inner {
        height: 45px !important;
        border: none;
        color: #222;
        font-size: 16px;
        padding-left: 40px;
        box-sizing: border-box;
        background-color: #f5f5f5;
        border-radius: 5px;

        &:focus {
          border: 1px solid #409eff;
          background: #ffffff;
        }
      }
      /deep/.el-input__prefix {
        left: 10px;
      }

      .flex {
        justify-content: space-between;
      }

      .code {
        height: 45px;
        img {
          height: 100%;
        }
      }

      .login-button {
        background-color: #2196f3;
        border-color: #2196f3;
        width: 100%;
        height: 45px;
        font-size: 18px;
      }
    }
  }
  /deep/.el-form-item--small .el-form-item__content {
    line-height: 54px;
  }
}
</style>
