<template>
  <div class="header">
    <div class="top">
      <div class="logo flex-space-between">
        <div class="top-left flex">
          <div class="logo-img">
            <a href="https://zwfw.xinjiang.gov.cn/">
              <img src="../../../assets/images/页头logo.png" alt="" height="55" />
              <ul>
                <li>
                  <a href="http://gjzwfw.www.gov.cn/">国家政务服务平台</a>
                </li>
                <li>
                  <a href="http://www.xinjiang.gov.cn/">新疆维吾尔自治区人民政府网</a>
                </li>
              </ul>
            </a>
            <a href="https://zwfw.xinjiang.gov.cn">
              <img src="../../../assets/images/dashboard/logo2_new.png" :alt="logoImgAlt" height="50" />
            </a>
          </div>
          <div class="location-wrap" @click="showBsdtDialog">
            <div class="location-top">
              <img src="../../../assets/images/dashboard/icon-1.png" alt="" width="13" height="15" />
              <span v-if="!isShowBsdtName">切换区域与办事大厅</span>
              <span v-else>{{ selectedBsdtTitle }}</span>
              <!-- <i class="el-icon-caret-bottom arrow-icon"></i> -->
            </div>
          </div>
        </div>
        <div class="top-right">
          <div class="top-right-login flex" v-if="isLogined">
            <p v-if="!$hasPermission('IEBDC:DL:TYRZ')" @click="$emit('isShowLogin')">登录</p>
            <p v-if="$hasPermission('IEBDC:DL:TYRZ')" @click="goHnlogin()">登录</p>

            <!--TODO 政务网登录对接 -->
            <!-- <p @click="goHnlogin()">登录</p> -->
            <!-- <p>|</p> -->
            <p v-if="!$hasPermission('IEBDC:DL:TYRZ')" @click="$router.push('/sign-in')">注册</p>
            <p v-if="$hasPermission('IEBDC:DL:TYRZ')" @click="goHnRegister()">注册</p>
            <p @click="$emit('isShowJBrLogin')">企业之家</p>
            <!-- <p @click="goHnRegister()">注册</p> -->
          </div>
          <div class="top-right-logined" v-else>
            <router-link :to="{ name: 'user-center' }">
              <text-tooltip
                :content="userData.name ? userData.name : userData.agentUser.realName"
              ></text-tooltip>
            </router-link>
            <i class="el-icon-arrow-down mr15"></i>
            <!--<span class="shStatus-span" v-if="userData.realNameStatus !== 1 && userData.status !== 'SHZ'">[待认证]</span>-->
            <span class="shStatus-span" v-if="!userData.agentUser && userData.status === 'SHZ'">[待审核]</span>
            <span
              v-if="
                $store.getters['jbrlist-data/getJbrListData'].hasOwnProperty('institutionaluser') ||
                userData.hasOwnProperty('jbrListData')
              "
              @click="switchRoles"
              >切换角色 | </span
            ><span @click="loginOut">退出登录</span>
          </div>
        </div>
      </div>

      <div class="nav-wrap">
        <el-menu
          mode="horizontal"
          background-color="#fff"
          text-color="#333"
          active-text-color="#2c8bff"
          :default-active="activeIndex"
          router
          class="full-height"
        >
          <Navbar :navMenus="navMenus" />
        </el-menu>
      </div>
    </div>
    <el-dialog
      :visible.sync="bsdtDialogVisible"
      center
      :title="'请先选择办事大厅'"
      @close="closeDialog"
      custom-class="dialog-class"
    >
      <div class="bsdt-dialog-content-wrap">
        <!-- 市 -->
        <div class="bsdt-item flex" v-if="chooseBsdtData.sheng">
          <div class="bsdt-item-label">市:</div>
          <div class="bsdt-item-content">
            <template v-for="(item, index) in chooseBsdtData.sheng.childrens">
              <span
                :class="chooseBsdtData.shi.code === item.code ? 'chosen-bsdt-span' : ''"
                class="bsdt-span"
                v-if="item.visible"
                :key="index"
                @click="chooseBsdt(item)"
              >
                {{ item.title }}
              </span>
            </template>
          </div>
        </div>

        <!-- 区域 -->
        <div class="bsdt-item flex" v-if="selectedShiTitle.length">
          <div class="bsdt-item-label">区域:</div>
          <div class="bsdt-item-content">
            <template v-for="(item, index) in chooseBsdtData.shi.childrens">
              <span
                :class="chooseBsdtData.xian.code === item.code ? 'chosen-bsdt-span' : ''"
                class="bsdt-span"
                v-if="item.visible"
                :key="index"
                @click="chooseBsdt(item)"
              >
                {{ item.title }}
              </span>
            </template>
          </div>
        </div>

        <!-- 办事大厅 -->
        <div class="bsdt-item flex" v-if="selectedAreaTitle.length">
          <div class="bsdt-item-label color2c8bff">办事大厅:</div>
          <div class="bsdt-item-content">
            <span
              :class="selectedBsdtTitle === item.officeName ? 'chosen-bsdt-span' : ''"
              class="bsdt-span"
              v-for="(item, index) in chooseBsdtData.xian.bsdtData"
              :key="index"
              @click="clickBsdt(item)"
            >
              {{ item.officeName }}
            </span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Navbar from '@/pages/iebdc/layout/Navbar.vue';
import { localDataUser } from '@/pages/iebdc/utils/local-data.js';
import { area, getOrganBsdt, getWorkOffice } from '../../../api/uums';
import { mapGetters, mapState } from 'vuex';
import { addCookie, removeCookie } from '../../../utils/cookies.js';
import { getCookie } from '@iebdc/utils/cookies';
import { login, login1 } from '@iebdc/api/zww-login';
import { localDataArea } from '../../../utils/local-data';
import { filterArea, filterAreaAll } from './filterArea.js';
import bsdtDialog from '@iebdc/mixin/bsdtDialog';
import { subRoutes } from 'iebdc/router';
let zlData = {
  zlProvince: getCookie('selectedShengCode') || '650000',
  zlCity: getCookie('selectedShiCode') || '',
  zlArea: getCookie('selectedAreaCode') || '',
};
export default {
  components: {
    Navbar,
  },
  props: {
    isShowLogin: Function,
    isShowJBrLogin: Function,
  },
  mixins: [bsdtDialog],
  data() {
    let _this = this;
    const subRoutesCopy = JSON.parse(JSON.stringify(subRoutes));
    const navMenus = (function recursive(arr) {
      const result = [];
      arr.forEach((item) => {
        if (item.path && item.meta && !item.meta.isNotMenu && _this.$hasPermission(item.meta.permission)) {
          result.push(item);
          const children = item.children;
          if (children && children.length) {
            item.children = recursive(children);
          }
          if (!item.children || !item.children.length) {
            delete item.children;
          }
        }
      });
      return result;
    })(subRoutesCopy);
    return {
      navMenus, // 依赖路由配置
      activeIndex: '1',
      codeValue1: '',
      codeValue: '',
      ready: false,
      logo: '',
      logoImgAlt: '',
      // isLogined: true, // 判断是否登录
      userData: localDataUser.get(),
      bsdtTitle: '',
      areaData: [], // 省/市/县(区) 数据源
      orgData: [], // 县(区) 下所有办事大厅 数据源
      bsdtData: [], // 市 所有的办事大厅 数据源
      chooseBsdtData: {
        // 当前展示的 办事大厅弹窗数据源
        sheng: '',
        shi: {},
        xian: {},
        // bsdtArr: [],
        bsdtObj: {},
      },
    };
  },
  inject: ['customImagePath'],
  computed: {
    // 办事大厅弹窗状态
    ...mapState('dashboard', ['selectedAreaTitle', 'selectedShiTitle', 'bsdtDialogVisible']),
    // 获取存在vuex 中的 选中的办事大厅名字以及code++++选中的 区域(区/县)的名字和code
    ...mapGetters(['selectedBsdtTitle', 'selectedBsdtCode', 'selectedAreaTitle', 'selectedAreaCode']),

    // 是否登录
    isLogined() {
      if (this.userData) {
        return false;
      } else {
        return true;
      }
    },

    // 是否展示办事大厅的名称
    isShowBsdtName() {
      return this.selectedBsdtCode ? true : false;
    },
  },
  methods: {
    // 页面初始化
    async init() {
      area().then((res) => {
        // localStorage.setItem('finalAreaDataLocal', JSON.stringify(finalAreaData));
        this.$store.commit('dashboard/setSelectedShengCode', res.data[0].code);
        this.$store.commit('dashboard/setSelectedShengTitle', res.data[0].title);
        addCookie('selectedShengTitle', this.$store.state.dashboard.selectedShengTitle, 1);
        addCookie('selectedShengCode', this.$store.state.dashboard.selectedShengCode, 1);
      });
      this.initSystemInfo();
      if (getCookie('selectedAreaTitle') === '' && getCookie('selectedShengTitle') === '') this.getBsdtDialogData();
      else {
        this.getCookieBsdt();
        if (localDataUser.get() && localDataUser.get().accountArea) {
          this.initBsdtDialogData(filterArea(localDataArea.get(), localDataUser.get().accountArea));
        } else {
          this.initBsdtDialogData(filterAreaAll(localDataArea.get()));
        }
      }
    },
    initSystemInfo() {
      const { APP_INFO } = window.CONFIG;
      this.logo = this.customImagePath + APP_INFO.iebdc.header;
      this.logoImgAlt = APP_INFO.systemName;
    },
    closeDialog() {
      if (localDataUser.get() && !getCookie('selectedBsdtCode')) {
        this.$message.error('请选择办事大厅！');
        this.$store.commit('dashboard/isShowBsdtDialog', true);
      } else {
        this.$store.commit('dashboard/isShowBsdtDialog', false);
      }
      // this.$store.commit('dashboard/isShowBsdtDialog', false);
    },
    // 登录
    goHnlogin() {
      window.location.href = `${this.$decryptByDES( 
        window.CONFIG.LOGIN_TO_ZWW.url
      )}/xjwwrz/login/oauth2login?client_id=${
        window.CONFIG.LOGIN_TO_ZWW.client_id
      }&state=a&response_type=code&scope=user&redirect_uri=${this.$decryptByDES(
        window.CONFIG.LOGIN_TO_ZWW.redirect_uri
      )}`;
    },
    testLogin() {
      login({
        outside: '',
        client_id: window.CONFIG.LOGIN_TO_ZWW.client_id,
        state: 'a',
        code: this.codeValue,
        redirect_uri: this.$decryptByDES(window.CONFIG.LOGIN_TO_ZWW.redirect_uri),
      });
    },
    testLogin1() {
      login1({
        outside: '',
        client_id: window.CONFIG.LOGIN_TO_ZWW.client_id,
        state: 'a',
        code: this.codeValue1,
        redirect_uri: this.$decryptByDES(window.CONFIG.LOGIN_TO_ZWW.redirect_uri),
      });
    },
    //注册
    goHnRegister() {
      // http://220.171.42.82:8082/xjwwrz/pages/login/personalRegister ?client_id=client_id&redirect_uri=http://www.baidu.com/
      //   window.location.href = `${window.CONFIG.UNIFIED_REGISTER}?backUrl=${window.CONFIG.UNIFIED_PAGE}`;
      window.location.href = `${this.$decryptByDES(
        window.CONFIG.LOGIN_TO_ZWW.url
      )}/xjwwrz/pages/login/personalRegister?client_id=${
        window.CONFIG.LOGIN_TO_ZWW.client_id
      }&redirect_uri=${this.$decryptByDES(window.CONFIG.LOGIN_TO_ZWW.redirect_uri)}`;
    },
    // 退出
    loginOut() {
      sessionStorage.clear();
      localStorage.removeItem('IEBDC_ZWW_SX');
      // const user = localDataUser.get();
      localDataUser.clear();
      this.userData = localDataUser.set('');
      this.isShowBsdtName = false;
      removeCookie('selectedAreaTitle');
      removeCookie('selectedAreaCode');

      if (!this.$hasPermission('IEBDC:DL:TYRZ')) {
        localDataUser.clear();
        this.$message.success('退出成功！');
        setTimeout(() => {
          location.reload(); //解决从首页登录后再从首页登出，页面不刷新问题
          this.$router.push('/home-login-out');
        }, 500);
        // window.location.href = `${window.CONFIG.UNIFIED_LOGOUT}?backUrl=${window.CONFIG.UNIFIED_PAGE}`;
      } else {
        window.location.href = `${this.$decryptByDES(
          window.CONFIG.LOGIN_TO_ZWW.url
        )}/xjwwrz//rest/oauth2/logout?redirect_uri=${this.$decryptByDES(
          window.CONFIG.LOGIN_TO_ZWW.redirect_uri
        )}&logout=1`;
      }
    },

    // 显示选择办事大厅的弹窗
    showBsdtDialog() {
      this.getBsdtDialogData();
      this.$store.commit('dashboard/isShowBsdtDialog', true);
    },

    async getBsdtDialogData() {
      // 政务网企业、银行账户跳转到网上大厅，需走审核功能（企业用户可以修改自己的所属区域，审核过后的用户只能选择自己所属区办理业务）
      if (localDataUser.get() && localDataUser.get().accountArea) {
        // 省/市/县(区) 所有的区域数据
        let areaData = await area();
        let areaLength = areaData.data.length;
        if (areaLength && areaLength > 0) {
          for (let i = 0; i < areaLength; i++) {
            const areaItem = areaData.data[i];
            this.bsdtTitle = areaItem.title;
            if (areaItem.code === window.CONFIG.code) {
              this.areaData.push(areaItem);
            }
          }
        }

        // 所有的机构数据
        let orgData = await getOrganBsdt();
        let orgLength = orgData.data.length;
        if (orgLength && orgLength > 0) {
          for (let i = 0; i < orgLength; i++) {
            const orgItem = orgData.data[i];
            if (orgItem.code === window.CONFIG.code) {
              this.orgData.push(orgItem);
            }
          }
        }

        // 区域下的所有的办事大厅数据
        let bsdtData = await getWorkOffice({ nSize: 10000 });
        this.bsdtData = [...bsdtData.data.page];

        // 重构 区域数据源---areaData---将区县与办事大厅通过areaId连接起来，同时兼顾市辖区的情况
        this.rebuildAllData(this.areaData, this.orgData, this.bsdtData);

        //找到当前设定区域下的值
        let finalAreaData = this.getAllNodes(this.areaData, 'childrens').filter((item) => {
          return item.code === window.CONFIG.code;
        });
        localStorage.setItem('finalAreaDataLocal', JSON.stringify(finalAreaData));
        // 将重构的后的区域数据 放入vuex
        this.$store.commit('dashboard/setFinalAreaData', finalAreaData);

        // 获取 cookie 中的 办事大厅弹窗的数据
        this.getCookieBsdt();

        // 如果 vuex 里没有选择 区域(区/县)
        if (!this.selectedAreaCode) {
          this.chooseBsdt(finalAreaData[0]);
        }
        this.initBsdtDialogData(filterArea(localDataArea.get(), localDataUser.get().accountArea));
        if (getCookie('selectedAreaTitle') === '' && getCookie('selectedShengTitle') === '') {
          this.chooseBsdt(filterArea(localDataArea.get(), localDataUser.get().accountArea)[0].childrens[0]);
        }
      } else {
        // 省/市/县(区) 所有的区域数据
        let areaData = await area();
        let areaLength = areaData.data.length;
        if (areaLength && areaLength > 0) {
          for (let i = 0; i < areaLength; i++) {
            const areaItem = areaData.data[i];
            this.bsdtTitle = areaItem.title;
            if (areaItem.code === window.CONFIG.code) {
              this.areaData.push(areaItem);
            }
          }
        }

        // 所有的机构数据
        let orgData = await getOrganBsdt();
        let orgLength = orgData.data.length;
        if (orgLength && orgLength > 0) {
          for (let i = 0; i < orgLength; i++) {
            const orgItem = orgData.data[i];
            if (orgItem.code === window.CONFIG.code) {
              this.orgData.push(orgItem);
            }
          }
        }

        // 区域下的所有的办事大厅数据
        let bsdtData = await getWorkOffice({ nSize: 10000 });
        this.bsdtData = [...bsdtData.data.page];

        // 重构 区域数据源---areaData---将区县与办事大厅通过areaId连接起来，同时兼顾市辖区的情况
        this.rebuildAllData(this.areaData, this.orgData, this.bsdtData);

        //找到当前设定区域下的值
        let finalAreaData = this.getAllNodes(this.areaData, 'childrens').filter((item) => {
          return item.code === window.CONFIG.code;
        });
        localStorage.setItem('finalAreaDataLocal', JSON.stringify(finalAreaData));
        // 将重构的后的区域数据 放入vuex
        this.$store.commit('dashboard/setFinalAreaData', finalAreaData);
        // console.log(finalAreaData);
        // 获取 cookie 中的 办事大厅弹窗的数据
        this.getCookieBsdt();

        // 如果 vuex 里没有选择 区域(区/县)
        if (!this.selectedAreaCode) {
          this.chooseBsdt(finalAreaData[0]);
        }

        // 初始化办事大厅弹窗内容
        this.initBsdtDialogData(filterAreaAll(finalAreaData));
      }
    },

    rebuildAllData(areaData, orgData, bsdtData) {
      //重构 bsdtData 数据，给办事大厅加上所属区县的areaId
      this.rebuildBsdtData(orgData, bsdtData);

      //重构areaData 数据, 将区县与办事大厅通过areaId连接后的bsdtDat,放入areaData的相同的areaId的bsdtData属性里
      let allAreaData = this.getAllNodes(this.areaData, 'childrens');
      for (let ii = 0; ii < allAreaData.length; ii++) {
        let areaItem = allAreaData[ii];
        areaItem.bsdtData = [];
        for (let jj = 0; jj < bsdtData.length; jj++) {
          if (areaItem.id === bsdtData[jj].areaId) {
            areaItem.bsdtData.push(bsdtData[jj]);
          }
        }
      }
    },

    //重构 bsdtData
    rebuildBsdtData(orgData, bsdtData) {
      if (orgData === null || bsdtData === null) {
        return false;
      }
      let allOrgData = this.getAllNodes(orgData, 'childrens');
      for (let ii = 0; ii < bsdtData.length; ii++) {
        let bsdtItem = bsdtData[ii];
        for (let jj = 0; jj < allOrgData.length; jj++) {
          if (allOrgData[jj].code === bsdtItem.officeCode) {
            bsdtItem.areaId = allOrgData[jj].areaId;
            break;
          }
        }
      }
    },

    // 扁平化数据
    getAllNodes(arrayNodes, attChildren) {
      if (arrayNodes === null) {
        return [];
      }
      attChildren = attChildren === null ? 'children' : attChildren;
      let result = [];
      for (let ii = 0; ii < arrayNodes.length; ii++) {
        let pNode = arrayNodes[ii];
        result.push(pNode);
        if (pNode[attChildren]) {
          let arrayTempNodes = this.getAllNodes(pNode[attChildren], attChildren);
          result = result.concat(arrayTempNodes);
        }
      }
      return result;
    },

    /*
     * 添加cookies
     */
    addAreaCookie(item) {
      switch (item.areaType) {
        // 当是省时,把当前 选中的省 存入vuex 同时存入cookie，同时清空选中的办事大厅
        case '2':
          this.$store.commit('dashboard/setSelectedShengCode', item.code);
          this.$store.commit('dashboard/setSelectedShengTitle', item.title);
          addCookie('selectedShengTitle', this.$store.state.dashboard.selectedShengTitle, 1);
          addCookie('selectedShengCode', this.$store.state.dashboard.selectedShengCode, 1);
          this.removeAreaCookie();
          break;
        // 当是市时,把当前 选中的市 存入vuex 同时存入cookie，同时清空选中的办事大厅
        case '3':
          this.$store.commit('dashboard/setSelectedShiCode', item.code);
          this.$store.commit('dashboard/setSelectedShiTitle', item.title);
          addCookie('selectedShiTitle', this.$store.state.dashboard.selectedShiTitle, 1);
          addCookie('selectedShiCode', this.$store.state.dashboard.selectedShiCode, 1);
          zlData.zlCity = item.code;
          this.removeAreaCookie('shi');
          break;
        // 当是区县时,把当前 选中的区域(区/县) 存入vuex 同时存入cookie，同时清空选中的办事大厅
        case '4':
          this.$store.commit('dashboard/setSelectedAreaCode', item.code);
          this.$store.commit('dashboard/setSelectedAreaTitle', item.title);
          addCookie('selectedAreaTitle', this.$store.state.dashboard.selectedAreaTitle, 1);
          addCookie('selectedAreaCode', this.$store.state.dashboard.selectedAreaCode, 1);
          zlData.zlArea = item.code;
          this.removeAreaCookie('xian');
          break;
        // 当选中的是 办事大厅时，选中的办事大厅 相关数据存入vuex和cookie中
        default:
          this.$store.commit('dashboard/setSelectedBsdtCode', item.officeCode);
          this.$store.commit('dashboard/setSelectedBsdtTitle', item.officeName);
          this.$store.commit('dashboard/setSelectedOrganizationsCode', item.djjg);
          this.$store.commit('dashboard/setSelectedOrganizationsTitle', item.jgmc);
          addCookie('selectedBsdtTitle', this.$store.state.dashboard.selectedBsdtTitle, 1);
          addCookie('selectedBsdtCode', this.$store.state.dashboard.selectedBsdtCode, 1);
          addCookie('selectedOrganizationsTitle', this.$store.state.dashboard.selectedOrganizationsTitle, 1);
          addCookie('selectedOrganizationsCode', this.$store.state.dashboard.selectedOrganizationsCode, 1);
          this.chooseBsdtData.bsdtObj = item;
          this.$bus.$emit('selectedOrganizationsCode', item.djjg);
          this.$bus.$emit('selectedZlData', zlData);
      }
    },

    /*
     * 移除cookies
     */
    removeAreaCookie(item) {
      switch (item) {
        case 'shi':
          this.$store.commit('dashboard/setSelectedBsdtTitle', '');
          this.$store.commit('dashboard/setSelectedBsdtCode', '');
          this.$store.commit('dashboard/setSelectedOrganizationsTitle', '');
          this.$store.commit('dashboard/setSelectedOrganizationsCode', '');
          this.$store.commit('dashboard/setSelectedAreaTitle', '');
          this.$store.commit('dashboard/setSelectedAreaCode', '');
          removeCookie('selectedBsdtTitle');
          removeCookie('selectedBsdtCode');
          removeCookie('selectedOrganizationsTitle');
          removeCookie('selectedOrganizationsCode');
          removeCookie('selectedAreaTitle');
          removeCookie('selectedAreaCode');
          break;
        case 'xian':
          this.$store.commit('dashboard/setSelectedBsdtTitle', '');
          this.$store.commit('dashboard/setSelectedBsdtCode', '');
          removeCookie('selectedBsdtTitle');
          removeCookie('selectedBsdtCode');
          removeCookie('selectedOrganizationsTitle');
          removeCookie('selectedOrganizationsCode');
          break;
        default:
          this.$store.commit('dashboard/setSelectedBsdtTitle', '');
          this.$store.commit('dashboard/setSelectedBsdtCode', '');
          this.$store.commit('dashboard/setSelectedOrganizationsTitle', '');
          this.$store.commit('dashboard/setSelectedOrganizationsCode', '');
          this.$store.commit('dashboard/setSelectedAreaTitle', '');
          this.$store.commit('dashboard/setSelectedAreaCode', '');
          this.$store.commit('dashboard/setSelectedShiTitle', '');
          this.$store.commit('dashboard/setSelectedShiCode', '');
          removeCookie('selectedBsdtTitle');
          removeCookie('selectedBsdtCode');
          removeCookie('selectedOrganizationsTitle');
          removeCookie('selectedOrganizationsCode');
          removeCookie('selectedAreaTitle');
          removeCookie('selectedAreaCode');
          removeCookie('selectedShiTitle');
          removeCookie('selectedShiCode');
      }
    },

    // 获取 cookie 中的 办事大厅弹窗的数据
    getCookieBsdt() {
      let arrCookie = document.cookie.split('; ');
      for (let i = 0; i < arrCookie.length; i++) {
        let arr = arrCookie[i].split('=');
        if (arr[0] === 'selectedBsdtTitle') {
          this.$store.commit('dashboard/setSelectedBsdtTitle', unescape(arr[1]));
          this.chooseBsdtData.sheng = unescape(arr[1]);
        } else if (arr[0] === 'selectedBsdtCode') {
          this.$store.commit('dashboard/setSelectedBsdtCode', unescape(arr[1]));
        } else if (arr[0] === 'selectedOrganizationsTitle') {
          this.$store.commit('dashboard/setSelectedOrganizationsTitle', unescape(arr[1]));
        } else if (arr[0] === 'selectedOrganizationsCode') {
          this.$store.commit('dashboard/setSelectedOrganizationsCode', unescape(arr[1]));
        } else if (arr[0] === 'selectedAreaTitle') {
          this.$store.commit('dashboard/setSelectedAreaTitle', unescape(arr[1]));
        } else if (arr[0] === 'selectedAreaCode') {
          this.$store.commit('dashboard/setSelectedAreaCode', unescape(arr[1]));
        } else if (arr[0] === 'selectedShengTitle') {
          this.$store.commit('dashboard/setSelectedShengTitle', unescape(arr[1]));
        } else if (arr[0] === 'selectedShengCode') {
          this.$store.commit('dashboard/setSelectedShengCode', unescape(arr[1]));
        } else if (arr[0] === 'selectedShiTitle') {
          this.$store.commit('dashboard/setSelectedShiTitle', unescape(arr[1]));
        } else if (arr[0] === 'selectedShiCode') {
          this.$store.commit('dashboard/setSelectedShiCode', unescape(arr[1]));
        }
      }
    },
    //切换角色
    switchRoles() {
      if (
        this.userData.hasOwnProperty('jbrListData') &&
        !this.$store.getters['jbrlist-data/getJbrListData'].hasOwnProperty('institutionaluser')
      ) {
        this.$store.commit('jbrlist-data/setJbrListData', this.userData.jbrListData);
      }
      this.$jbr();
    },

    //点击 市 区域或者办事大厅的选项
    chooseBsdt(item) {
      const zjurl = window.CONFIG.APP_INFO.zjUrl;
      this.addAreaCookie(item);
      switch (item.areaType) {
        case '2':
          //省
          this.chooseBsdtData.sheng = item;
          this.chooseBsdtData.bsdtObj = null;
          // this.chooseBsdt(this.chooseBsdtData.sheng.childrens[0]);
          break;
        case '3':
          //市
          this.chooseBsdtData.shi = item;
          this.chooseBsdtData.bsdtObj = null;
          // this.chooseBsdt(this.chooseBsdtData.shi.childrens[0]);
          break;
        case '4':
          //县
          this.chooseBsdtData.xian = item;
          this.chooseBsdtData.bsdtObj = null;
          if (this.chooseBsdtData.xian.bsdtData && this.chooseBsdtData.xian.bsdtData.length <= 1) {
            console.log('this.chooseBsdtData.xian', this.chooseBsdtData.xian);
            this.chooseBsdt(this.chooseBsdtData.xian.bsdtData[0]);
          }
          //   this.chooseBsdt(this.chooseBsdtData.xian.bsdtData[0]);
          break;
        default:
          //办事大厅
          this.chooseBsdtData.bsdtObj = item;
          this.$store.commit('dashboard/isShowBsdtDialog', false);
          if (zjurl.hasOwnProperty(item.officeName)) {
            this.removeAreaCookie();
            window.location.href = this.$decryptByDES(zjurl[item.officeName]);
          }
      }
    },
    clickBsdt(item) {
      this.addAreaCookie(item);
      this.chooseBsdtData.bsdtObj = item;
      this.$store.commit('dashboard/isShowBsdtDialog', false);
      const zjurl = window.CONFIG.APP_INFO.zjUrl;
      if (zjurl.hasOwnProperty(item.officeName)) {
        this.removeAreaCookie();
        window.location.href = this.$decryptByDES(zjurl[item.officeName]);
      }
    },

    // 初始化办事大厅弹窗里的内容
    initBsdtDialogData(finalAreaData) {
      //省
      for (let i = 0, len = finalAreaData.length; i < len; i++) {
        if (finalAreaData[i].code === window.CONFIG.code) {
          this.chooseBsdtData.sheng = finalAreaData[i];
        }
      }
      //市
      if (
        !this.chooseBsdtData.sheng ||
        !this.chooseBsdtData.sheng.childrens ||
        this.chooseBsdtData.sheng.childrens.length <= 0
      ) {
        return;
      }
      for (let i = 0, len = this.chooseBsdtData.sheng.childrens.length; i < len; i++) {
        if (this.chooseBsdtData.sheng.childrens[i].code === this.$store.state.dashboard.selectedShiCode) {
          this.chooseBsdtData.shi = this.chooseBsdtData.sheng.childrens[i];
          this.$store.commit('dashboard/setSelectedShiTitle', this.chooseBsdtData.shi.title);
        }
      }
      //县
      if (
        !this.chooseBsdtData.shi ||
        !this.chooseBsdtData.shi.childrens ||
        this.chooseBsdtData.shi.childrens.length <= 0
      ) {
        return;
      }
      for (let i = 0, len = this.chooseBsdtData.shi.childrens.length; i < len; i++) {
        if (this.chooseBsdtData.shi.childrens[i].code === this.$store.state.dashboard.selectedAreaCode) {
          this.chooseBsdtData.xian = this.chooseBsdtData.shi.childrens[i];
          this.$store.commit('dashboard/setSelectedAreaTitle', this.chooseBsdtData.xian.title);
        }
      }
      if (this.chooseBsdtData.xian.bsdtData) {
        for (let i = 0, len = this.chooseBsdtData.xian.bsdtData.length; i < len; i++) {
          if (this.chooseBsdtData.xian.bsdtData[i].officeCode === this.$store.state.dashboard.selectedBsdtCode) {
            this.chooseBsdtData.bsdtObj = this.chooseBsdtData.xian.bsdtData[i];
          }
        }
      }

      this.ready = true;
    },
  },
  mounted() {
    this.init();
    console.log('userData', this.userData)
  },
};
</script>

<style lang="scss" scoped>
@import '~@/pages/iebdc/styles/common-variables.scss';
@import '~@/pages/iebdc/styles/public.scss';

.color2c8bff {
  color: #2c8bff;
}

.header {
  width: 100%;
  color: white;
  .loginBox {
    display: inline-block;
    max-width: 256px;
    overflow: hidden;
  }
  .top {
    // background: url(https://zwfw.xinjiang.gov.cn/images/banner01.png) no-repeat -237px 0;
    background: url('../../../assets/images/banner-bg.png') no-repeat;
    // background-position: center 0;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    .logo {
      padding-top: 24px;
      padding-bottom: 24px;
      margin: 0px 100px;
      height: 115px;
      .top-left {
        a {
          display: block;
        }
        .location-wrap {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          cursor: pointer;
          margin: auto 35px;
          padding: 14px;

          .location-top {
            display: flex;
            align-content: center;
            img {
              vertical-align: middle;
              margin-right: 6px;
            }
            .area-name {
              margin: 0 10px 0 6px;
              color: #333;
              font-size: 18px;
              font-weight: bold;
            }

            .arrow-icon {
              color: #9b9b9b;
              font-size: 14px;
            }
          }

          p {
            color: #999;
            font-size: 14px;
          }
        }
        .logo-img {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // align-items: center;
          a {
            display: flex;
            align-items: center;
          }
          a:nth-child(1) {
            margin-bottom: 10px;
          }
        }
      }
      ul {
        list-style: none;
        display: flex;
        height: 100%;
        align-items: center;
        margin-left: 25px;
        li {
          height: 18px;
          line-height: 18px;
          font-size: 16px;
        }
        li:nth-child(1) {
          text-align: center;
          padding-left: 20px;
          background: url(../../../assets/images/icon-emblem.png) no-repeat 0 center;
        }
        li:nth-child(2) {
          text-align: center;
          padding-left: 10px;
          margin-left: 10px;
          border-left: 1px solid #fff;
        }
      }

      .top-right /deep/ {
        .el-button--small {
          padding: 9px 0px;
          font-size: 12px;
          border-radius: 3px;
          width: 200px;
          text-align: right;
          overflow: hidden;
          margin-right: 4px;
          border: none;
          color: #333333;
        }
        .el-button:hover,
        .el-button:focus {
          color: inherit;
          border-color: inherit;
          background-color: inherit;
        }
        .top-right-login {
          position: relative;
          border: 1px solid #fff;
          border-radius: 13px;
          p {
            // width: 60px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            font-size: 14px;
            // font-family: Microsoft YaHei;
            // font-weight: bold;
            color: white;
            margin-right: $spacing-base;
            padding: 0px 10px;
            // margin: 0;
            cursor: pointer;
            // &:hover {
            //   color: #2c8cff;
            // }
          }
        }
        // .top-right-login:before {
        //   position: absolute;
        //   content: '';
        //   left: 60px;
        //   top: 5px;
        //   width: 2px;
        //   height: 15px;
        //   background: #fff;
        // }
        .top-right-logined {
          color: white;
          span {
            cursor: pointer;

            &.shStatus-span {
              color: #f29f46;
            }
          }
        }
      }
    }
  }

  /deep/ .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        .el-dialog__title {
          font-size: 24px;
          font-weight: bold;
        }
      }
      .el-dialog__body {
        .bsdt-dialog-content-wrap {
          .bsdt-item {
            padding: $spacing-base 0;
            border-bottom: 1px dotted #d2d2d2;
            font-size: 18px;
            color: #333;

            .bsdt-item-label {
              width: 108px;
              line-height: 34px; // 为了与第一排的 区域对齐
              text-align: right;
              font-weight: bold;
            }

            .bsdt-item-content {
              flex: 1;

              .bsdt-span {
                display: inline-block;
                margin: 0 16px;
                padding: 8px 12px 8px 10px;
                cursor: pointer;
              }

              .chosen-bsdt-span {
                background: #2c8bff;
                color: #fff;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
  }
}

/deep/ .el-tooltip__popper {
  width: 280px !important;
  left: 1000px !important;
}
/deep/.nav-wrap {
  margin-left: calc(50% - 600px);
  padding: 0 15px;
  width: 1200px;
  height: 57px;
  color: write;
  .full-height {
    .el-row .colId li {
      display: flex;
      align-items: center;
    }
  }
  .el-menu.el-menu--horizontal {
    border-bottom: none;
    background-color: transparent !important;
  }
  .el-menu-item {
    font-size: 18px !important;
    font-weight: 400 !important;
    color: white !important;
    background-color: transparent !important;
  }

  .el-row {
    height: 57px;
    .colId {
      height: 100%;
    }
    .colId:nth-of-type(1) li {
      border-bottom: 3px solid white !important;
    }
    .colId li:hover {
      border-bottom: 3px solid white !important;
    }
    .is-active {
      border-bottom: 3px solid white !important;
    }
  }
  .el-row .el-col .el-menu-item:hover {
    background-color: transparent !important;
  }
}
</style>
