/**
 * jbrlist  store
 * @description
 * 经办人存储
 **/
const jbrlist = {
  namespaced: true,
  state: {
    jbrListData: {}, //经办人信息存储
  },
  getters: {
    getJbrListData: (state) => {
      return state.jbrListData;
    },
  },
  mutations: {
    //经办人信息存储
    setJbrListData(state, data) {
      state.jbrListData = data;
    },
  },
  actions: {},
};
export default jbrlist;
