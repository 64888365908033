import { getLocalData, setLocalData, clearLocalData } from '@iebdc/utils/local-storage';
export class AreaCacheObject {
  name = ''; // 区域选择缓冲名称
  constructor(name) {
    // 抛出重复的名称错误
    const existNames = AreaCacheObject._existNames;
    if (existNames.includes(name)) {
      throw `本地缓存错误：${name} 名称重复`;
    } else {
      existNames.push(name);
    }

    this.name = name;
  }

  get() {
    return getLocalData(this.name);
  }

  getByKey(key) {
    return getLocalData(this.name) ? getLocalData(this.name)[key] : '';
  }

  remove(key) {
    const data = getLocalData(this.name);
    const dataArr = Object.keys(data);
    if (dataArr.includes(key)) {
      delete data[key];
    }
  }
  // 清理
  removeAll() {
    clearLocalData(this.name);
  }
  // 设置
  set(data) {
    let temp = getLocalData(this.name);
    if (data && temp) {
      setLocalData(this.name, Object.assign(temp, data));
    } else {
      setLocalData(this.name, data);
    }
  }
}

AreaCacheObject._existNames = [];
